import { FC } from 'react';
import { AppTags } from '../../interfaces/domain/ow-app.modal';

// -----------------------------------------------------------------------------
interface TagProps {
  list: AppTags[];
}

// -----------------------------------------------------------------------------
export const TagList: FC<TagProps> = ({ list }): JSX.Element => {

  return (
    <>
      {list.map((tagItem, index) => {
        const className = tagItem.itemUrl ? 'tag-item-link' : 'tag-item';

        return (
          <li className={className} key={index}>
            {tagItem.itemUrl &&
                <a href={tagItem.itemUrl}>
                  {tagItem.itemName}
                </a>
            }
            {!tagItem.itemUrl &&
                tagItem.itemName
            }
          </li>
        );

      })}
    </>
  );
};
