import { FC } from 'react';
import { AdditionalFeature } from '../../interfaces/domain/ow-app.modal';

// -----------------------------------------------------------------------------
interface TagProps {
  list: AdditionalFeature[];
}

// -----------------------------------------------------------------------------
export const FeaturedTagList: FC<TagProps> = ({list}): JSX.Element => {
  if (!list || list.length === 0) {
    return (<></>);
  }

  return (
    <>
      {list.map((tagItem, index) => {
        const className = tagItem.featureUrl ? 'tag-item-link' : 'tag-item';

        return (
          <li className={className} key={index}>
            {tagItem.featureUrl && 
              <a href={tagItem.featureUrl}>
                {tagItem.featureIcon &&
                  <img src={tagItem.featureIcon} alt={tagItem.featureName} />
                }
                {tagItem.featureName}
              </a>
            }
            {!tagItem.featureUrl && 
              <>
                {tagItem.featureIcon &&
                  <img src={tagItem.featureIcon} alt={tagItem.featureName} />
                }
                {tagItem.featureName}
              </>
            }
          </li>
        );

      })}
    </>
  );
};
