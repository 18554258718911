import { FC, useContext, useRef, useState } from 'react';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';
import { kImageFolderUrl } from '../../services/constants';
import AppPageContext from '../../context/app-page-context';
import { useClassName } from '../../hooks/use-class-name';
import { useParams } from 'next/navigation';
import GlobalContext from '../../context/global-context';
import { handleToolTipMouseOut, handleToolTipOnOver } from '../shared/ow-tool-tip';

// -----------------------------------------------------------------------------
interface Props {
  isOpen: boolean;
  onClickToggleOpen: () => void;
}

// -----------------------------------------------------------------------------
enum SocialPlatforms {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Reddit = 'reddit'
}

export const ShareModal: FC<Props> = ({
  isOpen, onClickToggleOpen
}): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();
  const params = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const [copyText, setCopyText] = useState<string>(t('copyLink'));
  const [showCheck, setShowCheck] = useState<boolean>(false);

  const { config } = useContext(GlobalContext);
  const {kRedditURL, kTwitterURL, kFacebookURL } =
    config?.publicRuntimeConfig?.share;

  const {baseUrl } =
  config?.publicRuntimeConfig?.base;

  const className = useClassName({
    'modal-is-open': isOpen
  }, 'share-modal');

  const copiedClassName = useClassName({
    'hidden': showCheck
  }, '', [showCheck]);

  const checkClassName = useClassName({
    'hidden': !showCheck
  }, '', [showCheck]);

  const shareOnSocialMedia = (platform: SocialPlatforms): void => {
    let shareUrl;

    switch (platform) {
      case SocialPlatforms.Facebook:
        shareUrl = kFacebookURL;
        break;
      case SocialPlatforms.Twitter:
        shareUrl = kTwitterURL;
        break;
      case SocialPlatforms.Reddit:
        shareUrl = kRedditURL;
        break;
      default: console.log('wrong social media prop');
    }

    const w = Math.min(640, screen.width - 32),
      h = Math.min(480, screen.height - 32),
      l = (screen.width - w) / 2,
      t = (screen.height - h) / 2;
    const shareWindowSize = `width=${w},height=${h},left=${l},top=${t}`;

    window.open(shareUrl + location.href, '_blank', shareWindowSize);
  };

  const handleCopyUrl = async (): Promise<void> => {
    const clipboardText = inputRef.current.value;
    await navigator.clipboard.writeText(clipboardText);
    setCopyText(t('linkCopied'));
    setShowCheck(true);

    setTimeout(() => {
      setCopyText(t('copyLink'));
      setShowCheck(false);
    }, 2000);
  };

  return (
    <div className='app-share-modal'>
      <button
        className='share-button icon-btn share-on-desktop'
        onClick={onClickToggleOpen}
        data-tooltip={t('shareApp')}
        // eslint-disable-next-line react/no-unknown-property
        tooltip-position='top'
        onMouseOver={handleToolTipOnOver}
        onMouseOut={handleToolTipMouseOut}
      >
        <SpriteIcon icon='share' />
      </button>

      <div className={className}>
        <div className='share-modal-main'>
          <button
            className='clean-icon-btn close-modal'
            onClick={onClickToggleOpen}
          >
            <SpriteIcon icon='closeSmall' />
          </button>

          <div className='share-modal-content'>
            <img
              src={`${kImageFolderUrl}/app-page/share-app.svg`}
              alt={t('shareTheApp')}
              className='asset'
            />
            <h2>{t('shareApp')}</h2>
            <p>
              {`${t('suggest', { appName: app.title })}`}
            </p>

            <ul className='share-social-list'>
              <li className='share-item'>
                <button
                  onClick={() => shareOnSocialMedia(SocialPlatforms.Facebook)}
                  className='icon-btn'
                >
                  <SpriteIcon icon='Facebook' />
                </button>
              </li>
              <li className='share-item'>
                <button
                  onClick={() => shareOnSocialMedia(SocialPlatforms.Twitter)}
                  className='icon-btn'
                >
                  <SpriteIcon icon='Twitter' />
                </button>
              </li>
              <li className='share-item'>
                <button
                  onClick={() => shareOnSocialMedia(SocialPlatforms.Reddit)}
                  className='icon-btn'
                >
                  <SpriteIcon icon='Reddit' />
                </button>
              </li>
            </ul>

            <p className='or'>or</p>

            <div className='copy-url'>
              <input
                ref={inputRef}
                value={`${baseUrl}/app/${params.slug}`}
                spellCheck='false'
                readOnly
              />

              <button className='clean-icon-btn' onClick={handleCopyUrl}>
                <div className='copied-notice'>{copyText}</div>
                <SpriteIcon icon='copyUrl' svgClassName={copiedClassName} />
                <SpriteIcon icon='check' svgClassName={checkClassName} />
              </button>

            </div>
          </div>
        </div>
        <div className='bg' onClick={onClickToggleOpen} />
      </div>
    </div>
  );
};
