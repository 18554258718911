'use client';

import { useContext } from 'react';
import AppPageContext from '../../context/app-page-context';
import { useTranslation } from '../../hooks/use-translation';

export const Pairing = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();
  const pairedApps = app.richDetails?.pairedApps || null;

  if (!pairedApps || pairedApps.length === 0) {
    return null;
  }

  return (
    <section className='app-pairing-section'>
      <h2>{t('perfectPairing')} {app.title}</h2>

      <ul className='app-pairing-list'>
        {pairedApps.map((item, index) => {
          return (
            <li className='app-pairing-item' key={index}>
              <a href={item.link}>
                <div className='asset'>
                  <img src={item.imgSrc} alt={item.name} />
                </div>
                <div className='content'>
                  <h6>{item.name}</h6>
                  <p>{item.description}</p>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
