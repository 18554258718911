import { FC, useEffect, useState } from 'react';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';
import ReactPlayer from 'react-player/youtube';
import { dispatch } from '../../hooks/use-bus';
import { AppBusEventType } from '../../interfaces/bus-events';

// -----------------------------------------------------------------------------
interface VideoThumbProps {
  alt: string;
  ytID: string;
  onClick: () => void;
}
// -----------------------------------------------------------------------------
export const SliderVideoThumb: FC<VideoThumbProps> = ({
  ytID, alt, onClick
}): JSX.Element => {

  const ytURL = `https://www.youtube.com/watch?v=${ytID}`;
  const ytThumbnail = `https://i.ytimg.com/vi/${ytID}/maxresdefault.jpg`;
  const [hasWindow, setHasWindow] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  const handlePlayVideo = (): void => {
    onClick(); // will open the big slide

    dispatch({
      type: AppBusEventType.App.PlayVideoSlider,
      data: { ytID: ytID }
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <div
        className='video-thumb-container'
        onClick={handlePlayVideo}
      >
        <img
          src={ytThumbnail}
          alt={`${alt} screenshot image`}
        />
      
        {hasWindow && window.innerWidth > 860 &&
          <ReactPlayer
            className='yt-video'
            url={ytURL}
            width='100%'
            height='100%'
            muted={true}
            loop={true}
            controls={false}
            playing={true}
          />
        }

        <button className='play-btn'>
          <SpriteIcon icon='play' />
          {t('play')}
        </button>
      </div>
    </>
  );
};
