'use client';

import { useContext } from 'react';
import { useTranslation } from '../../hooks/use-translation';
import AppPageContext from '../../context/app-page-context';
import SpriteIcon from '../shared/sprite-icon';

export const AboutCreator = (): JSX.Element => {
  const { app } = useContext(AppPageContext);

  const { t } = useTranslation();

  if (!app?.richDetails?.aboutTheCreator) {
    return (
      <>
      </>
    );
  }

  const appAuthorName = app.richDetails?.authorNameOverride ?
    app.richDetails.authorNameOverride : app.author;

  return (
    <section className='app-creator-section'>
      <h2>{t('aboutTheCreator')}</h2>
      <div className='app-creator-content'>
        <div className='creator-info'>
          <div className='info'>
            <h3>
              {appAuthorName}
            </h3>

            <p dangerouslySetInnerHTML=
              {{__html: app.richDetails.aboutTheCreator.replace(/\n/g, '<br />')}} 
            />
          </div>
          <div className='creator-social'>

            {
              app.richDetails?.creatorSocialLinks &&
              app.richDetails?.creatorSocialLinks.length > 0 &&
              <>
                <h4>
                  {t('connectWithUs')}
                </h4>
                <ul className='creator-social-list'>

                  {
                    app.richDetails?.creatorSocialLinks?.map((item, index) => {
                      return (
                        <li key={index} className='social-item'>
                          <a
                            href={item.itemUrl}
                            className='icon-btn'
                            target='_blank'
                            title={item.itemName} rel='noreferrer'
                          >
                            <SpriteIcon icon={item.itemName} />
                          </a>
                        </li>

                      );
                    })
                  }
                </ul>
              </>
            }

          </div>
        </div>
        {
          app.richDetails.aboutTheCreatorImg &&
          <div className='creator-image'>
            <img src={app.richDetails.aboutTheCreatorImg} alt={appAuthorName} />
          </div>
        }
      </div>
    </section>

  );
};
